@import "../../../mixins";

.listing-card {
  position: relative;

  width: 100%;
  max-width: rem(454);
  display: flex;
  align-items: stretch;

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);
  overflow: hidden;

  transition: box-shadow var(--animation-default);

  @include mediaBigDesktop {
    max-width: big(454);

    border-width: big(1);
  }

  @include mediaDesktop {
    max-width: rem(357);
  }

  @include mediaLaptop {
    max-width: rem(263);
  }

  @include mediaTablet {
    max-width: rem(334);
  }

  @include mediaMobile {
    max-width: rem(270);
  }

  @include hover {
    box-shadow: var(--shadow-default);

    & .listing-card__image img {
      transform: scale(1.1);
    }
  }

  & > form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__top {
    position: relative;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;

    border-radius: var(--radius-common) var(--radius-common) 0 0;
  }
  
  &__favorites {
    position: absolute;
    top: rem(20);
    right: rem(20);
    z-index: 2;
    
    @include mediaBigDesktop {
      top: big(20);
      right: big(20);
    }

    @include mediaDesktop {
      top: rem(15);
      right: rem(15);
    }

    @include mediaLaptop {
      top: rem(15);
      right: rem(15);
    }
    
    & .button {
      --button-icon-color: var(--accent-secondary-default);
      --button-bg-color: var(--bg-white);
      --button-bg-color-hover: var(--accent-secondary-default);
        
      &.voted {
        --button-icon-color: var(--bg-white);
        --button-bg-color: var(--accent-secondary-default);
        --button-bg-color-hover: var(--accent-secondary-default);
        
        background-color: var(--button-bg-color);
      }
    }
  }

  &__article {
    position: absolute;
    top: rem(20);
    left: rem(20);
    z-index: 2;

    padding: rem(10) rem(15);
    width: fit-content;
    height: rem(44);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: var(--text-light-primary);
    font: var(--font-body-S-r);
    font-size: rem(16);
    white-space: nowrap;

    border-radius: rem(10);
    background-color: rgba(0, 0, 0, 0.15);

    pointer-events: none;

    @include mediaBigDesktop {
      top: big(20);
      left: big(20);

      padding: big(10) big(15);
      height: big(44);

      font-size: big(16);
    }

    @include mediaDesktop {
      top: rem(15);
      left: rem(15);

      padding: rem(8) rem(12);
      height: rem(38);

      font-size: rem(14);
    }

    @include mediaLaptop {
      top: rem(15);
      left: rem(15);

      padding: rem(5) rem(10);
      height: rem(30);

      font-size: rem(12);
    }

    @include mediaMobile {
      border-radius: rem(5);
    }

    & strong,
    & b {
      font-weight: 600;
    }
  }

  &__image {
    position: relative;
    z-index: 0;

    width: 100%;
    height: auto;
    aspect-ratio: 454/370;
    display: block;

    user-select: none;
    pointer-events: none;
    overflow: hidden;

    @supports not (aspect-ratio: 1/1) {
      height: rem(370);

      @include mediaBigDesktop {
        height: big(370);
      }
    }

    @include mediaDesktop {
      aspect-ratio: 357/290;

      @supports not (aspect-ratio: 1/1) {
        height: rem(290);
      }
    }

    @include mediaLaptop {
      aspect-ratio: 263/213;

      @supports not (aspect-ratio: 1/1) {
        height: rem(214);
      }
    }

    @include mediaTablet {
      aspect-ratio: 334/300;

      @supports not (aspect-ratio: 1/1) {
        height: rem(300);
      }
    }

    @include mediaMobile {
      aspect-ratio: 270/250;

      @supports not (aspect-ratio: 1/1) {
        height: rem(250);
      }
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-light);
      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-medium);
    }
  }

  &__bottom {
    padding: rem(30) rem(35) rem(35);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(25);

    @include mediaBigDesktop {
      padding: big(30) big(35) big(35);
      gap: big(25);
    }

    @include mediaDesktop {
      padding: rem(20) rem(30);
      gap: rem(15);
    }

    @include mediaLaptop {
      padding: rem(17) rem(20) rem(20);
      gap: rem(18);
    }

    @include mediaMobile {
      padding: rem(15) rem(20) rem(20);
      gap: rem(15);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(12);

    @include mediaBigDesktop {
      gap: big(12);
    }

    @include mediaDesktop {
      gap: rem(10);
    }

    @include mediaLaptop {
      gap: rem(8);
    }
  }

  &__title,
  &__description {
    display: -webkit-box;

    color: var(--text-dark-primary);
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    text-overflow: ellipsis;

    overflow: hidden;
  }

  &__title {
    &::before {
      @include pseudo(0, false, true) {
        & {
          z-index: 0;
        }
      }
    }
  }

  &__description {
    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
    -webkit-line-clamp: 2;

    @include mediaLaptop {
      -webkit-line-clamp: 3;
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__submit {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaLaptop {
      gap: rem(7);
    }
  }

  &__eye {
    &.button {
      --button-icon-color: var(--stroke-dark);
      --button-icon-color-hover: var(--accent-primary-default);
      --button-bg-color: var(--stroke-dark);
      --button-bg-color-hover: var(--accent-primary-default);
    }
  }

  &__next {
    &.button {
      --button-icon-color: var(--text-light-primary);
      --button-icon-color-hover: var(--text-light-primary);
      --button-bg-color: var(--accent-primary-default);
      --button-bg-color-hover: var(--accent-primary-hover);

      background-color: var(--button-bg-color);

      @include hover {
        background-color: var(--button-bg-color);
      }
    }
  }
}
